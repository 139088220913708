<template>
  <div>
    <router-view v-show="$route.matched.length==3"></router-view>
    <PageHeaderLayout v-show="$route.matched.length==2">
      <div class="main-page-content">
        <el-row class="table-header">
          <el-col>
            <el-tooltip effect="dark" content="添加新闻" placement="top-start"  v-if="userPermissions.indexOf('article_create') != -1 && buttonType=='icon'" >
              <el-button type="primary" size="medium" icon="iconfont icon-tianjiacaidan2" @click="addButton(0)"></el-button>
            </el-tooltip>
            <el-button type="primary" size="medium" icon="iconfont"  v-if="userPermissions.indexOf('article_create') != -1 && buttonType=='text'"  @click="addButton(0)">添加新闻</el-button>
          </el-col>
        </el-row>
        <el-row class="table-search">
          <el-form size="medium" :inline="true" :model="searchCondition" class="demo-form-inline">
            <el-form-item label="标题">
              <el-input v-model="searchCondition.title" placeholder="输入标题" clearable></el-input>
            </el-form-item>
            <el-form-item label="发布时间">
              <el-date-picker
                v-model="searchCondition.time_value"
                type="daterange"
                align="right"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-select v-model="article_lang" placeholder="请选择">
                <el-option
                  v-for="lang in langs"
                  :key="lang.value"
                  :label="lang.label"
                  :value="lang.value">
                </el-option>
              </el-select>
            </el-form-item>  
            <el-form-item>
              <el-button type="primary" @click="searchButton">查询</el-button>
            </el-form-item>
          </el-form>
        </el-row>
        <ApeTable ref="apeTable" :data="articleList" :columns="columns" :loading="loadingStaus" :pagingData="pagingData" @switchPaging="switchPaging" highlight-current-row border>
          <el-table-column
            slot="second-column"
            width="64"
            label="序号">
            <template slot-scope="scope">
              <span>{{offset+scope.$index+1}}</span>
            </template>
          </el-table-column>
          <el-table-column
            slot="second-column"
            width="120"
            label="封面">
            <template slot-scope="scope">
              <img :src="scope.row.cover_url" style="width: 64px; height:64px;">
            </template>
          </el-table-column>
          <el-table-column
            v-if="buttonType=='icon'"
            width="160"
            label="操作">
            <template slot-scope="scope">
              <span>
                
                <el-tooltip effect="dark" content="编辑" placement="top-start"  v-if="userPermissions.indexOf('article_edit') != -1" >
                  <el-button size="medium" icon="el-icon-edit" @click="editButton(scope.row.id)"></el-button>
                </el-tooltip>
                <el-tooltip effect="dark" content="删除" placement="top-start">
                  <span>
                    <el-popover
                      v-if="userPermissions.indexOf('article_delete') != -1" 
                      placement="top"
                      width="150"
                      v-model="scope.row.visible">
                      <p>确定要删除记录吗？</p>
                      <div style="text-align: right; margin: 0;">
                        <el-button type="text" size="medium" @click="scope.row.visible=false">取消</el-button>
                        <el-button type="danger" size="medium" @click="deleteButton(scope.row.id)">确定</el-button>
                      </div>
                      <el-button slot="reference" type="danger" size="medium" icon="el-icon-delete"></el-button>
                    </el-popover>
                  </span>
                </el-tooltip>
              </span>
            </template>
          </el-table-column>
        </ApeTable>
      </div>
    </PageHeaderLayout>
  
  </div >
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ApeTable from '@/components/ApeTable'
import ApeDrawer from '@/components/ApeDrawer'
import ApeUploader from '@/components/ApeUploader'
import ModalDialog from '@/components/ModalDialog'
import { mapGetters } from 'vuex'

export default {
  components: {
    PageHeaderLayout,
    ApeDrawer,
    ApeTable,
    ApeUploader,
    ModalDialog
  },
  data() {
    return {
      // element的cascader的props值
      cascaderProps:{
        label:'display_name',
        value:'id',
      },
      searchCategory: [],
      // 搜索条件
      searchCondition:{
        selected_category:[]
      },
      langs:[
        {
          label:'EN',
          value:'EN',
        }
      ],
      article_lang:'EN',
      searchArticleCondition:{
        "current_page": 1,
        "page_size": 10,
        "query":"article_lang=? and article_type=?",
        "params":["EN","2"]
      },
      searchCategoryCondition:{
        "current_page": 1,
        "page_size": 200,
        "query":"name=?",
        "params":["category_article"]
      },
      loadingStaus: true,
      columns: [
        {
          title: '信息',
          value: [
            {label:'ID号：',value:'article_id'},
            {label:'标题：',value:'title'},
            {label:'时间：',value:'published_time'},
          ],
          width: 380
        },
        {
          title: 'SEO',
          value: [
            {label:'SEO标题：',value:'seo_title'},
            {label:'SEO key：',value:'seo_keywords'},
            {label:'SEO描述：',value:'seo_description'},
          ]
        },
        {
          title: '状态',
          value: [
            {label:'发布：',value:'published_alias'},
            {label:'置顶：',value:'top_alias'},
            {label:'推荐：',value:'recommend_alias'},
          ],
          width:160
        }
      
        
      ],
      // 表格列表数据
      articleList:[],
      // 分页信息
      pagingData:{
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
      // 分页的offset,序号列使用
      offset:0,
      // 已上传文件列表
      uploadFileList:[],
      // 表单验证
      rules: {
        title: [
          {required: true, message: '输入标题', trigger: 'blur' },
        ],
        type: [
          {required: true, message: '选择类型', trigger: 'blur' },
        ],
        is_new_win: [
          {required: true, message: '选择打开发方式', trigger: 'blur' },
        ],
        is_show: [
          {required: true, message: '选择状态', trigger: 'blur' },
        ],
        image: [
          {required: true, message: '上传图片', trigger: 'blur', validator:this.apeUploaderVerify},
        ],
      },
      // 抽屉数据，附件列表
      drawerData: {
        visible: false,
        loading: true,
        loading_text: '玩命加载中……',
        // direction: 'right',
        title: '',
        width_height: '640px',
        show_footer:false,
        // mask: false,
        // close_name: '关 闭',
        // confirm_name: '打 印',
      },
      // 添加附件
      dialogData:{
        visible: false,
        title: '',
        width: '24%',
        loading: true,
        modal: false
      },
      // 附件表单内容
      formData:{},
      // 附件添加表单验证
      dialogRules: {
        title: [{required: true, message: '输入标题', trigger: 'blur' }],
      },
      currentArticleId:0,
    }
  },
  computed: {
    ...mapGetters(['userPermissions','buttonType'])
  },
  watch: {
    "$route.matched" : function(n) {
    if (n.length === 2) {
        this.initArticleList()
      }
    }
  },
  methods: {
    // 搜索
    searchButton() {
      this.initArticleList()
    },
    // 切换页码操作
    async switchPaging() {
      this.initArticleList()
    },
    // 响应添加按钮
    async addButton() {
      this.$router.push(this.$route.path+'/create')
    },
    // 响应编辑按钮
    async editButton(id) {
      this.$router.push(this.$route.path+'/'+ id +'/edit')
    },
    // 相应删除按钮
    async deleteButton(article_id) {
      let info = await this.$api.deleteArticle(article_id)
      if (info==null) {
        this.$nextTick(() => {
          this.initArticleList('del')
        })
      } else {
        this.$message.error(info)
      }
      
    },
    // 处理抽屉关闭
    drawerClose() {
      this.drawerData.visible = false
      this.drawerData.loading = true
    },
    // 初始化新闻列表
    async initArticleList() {
      this.loadingStaus=true
      this.handleSearchCondition()
      let {list,pages}= await this.$api.getArticleList(this.searchArticleCondition)
      let _arr = []
      if (list!=null){
          list.map((item) => {
            _arr.push(Object.assign({},item,{published_alias: item.is_published==1?'发布':'未发布',top_alias: item.is_top==1?'置顶':'未置顶',recommend_alias: item.is_recommend==1?'推荐':'未推荐'}))
          })
      }

      this.articleList = []
      this.$nextTick(() => {
        this.articleList=_arr
      })
      if (pages.offset==0){
        this.pagingData.total = pages.total
      }
      this.offset = pages.offset
      this.loadingStaus=false
    },
    // 处理搜索条件
    handleSearchCondition() {
      let pagingInfo = this.$refs['apeTable'].getPagingInfo()
      this.searchArticleCondition.page_size = pagingInfo.page_size
      this.searchArticleCondition.current_page = pagingInfo.current_page
      this.searchArticleCondition.query = 'article_lang=? and  article_type=?'
      this.searchArticleCondition.params = [this.article_lang,"2"]
      if (this.searchCondition.title) {
        this.setSearchCondition(' INSTR(title,?) ',this.searchCondition.title)
      }
      if (this.searchCondition.time_value) {
        this.setSearchCondition(' published_time  >= ? ',this.searchCondition.time_value[0])
        this.setSearchCondition(' published_time  <= ? ',this.searchCondition.time_value[1])
      }
    },
    setSearchCondition(condition,v) {
      if (this.searchArticleCondition.query == '' ){
          this.searchArticleCondition.query = condition
        }else{
          this.searchArticleCondition.query = this.searchArticleCondition['query'] + ' and ' + condition
        }
      this.searchArticleCondition.params.push(v)
    },
    // form数据提交，请求接口,新闻附件
    async formSubmit() {
      this.dialogData.loading_text = '玩命提交中……'
      this.dialogData.loading = true
      this.formData.article_id = this.currentArticleId
      let id = await this.$api.saveArticleAttachment(this.formData)
      this.$nextTick(() => {
        this.dialogClose()
      })
      this.$nextTick(() => {
        if (id) {
          this.initArticleAttachmentList()
        }
      })
      this.$nextTick(() => {
        this.$message.success('保存成功!')
      })
    },
    // 处理模态框，确定事件
    handleConfirm() {
      // 调用组件的数据验证方法
      this.$refs['articleAttacmentForm'].validate((valid) => {
        if (valid) {
          this.formSubmit()
        } else {
          this.$message.error('数据验证失败，请检查必填项数据！')
        }
      })
    },
    // 处理模态框，关闭事件
    dialogClose() {
      this.dialogData.visible = false
      this.dialogData.loading = true
      this.formData = {}
    },
  },
  mounted() {
    this.initArticleList()
  },
}
</script>

<style lang="stylus">
  .el-button
    margin-right 4px
    margin-bottom 4px
  .table-header
    margin-bottom 12px
  .el-input-group__prepend, .el-input-group__append
    background #ffffff
    padding 0 12px
  .el-input-group__append
    color #ffffff
    background #1890ff
  .el-popover .el-checkbox-group .el-checkbox
    margin-left 0px
    margin-right 12px
</style>
